import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [chapters, setChapters] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetch('https://api.thegita.org.in/api/v1/chapters')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setChapters(data))
      .catch(error => {
        setError('Error fetching data');
        console.error(error);
      });
  }, []);

  return (
    <div className="App">
      <h1>Bhagavad Gita Chapters</h1>
      {error && <p>{error}</p>}
     
      <div className="cards-container">
        {chapters.map(chapter => (
          <div key={chapter.chapterNumber} className="card">
            {/* First Row: Image */}
            <div className="card-image" style={{
              backgroundImage: `url('https://thegita.org.in/images/chapters/${chapter.chapterNumber}.jpeg')`
            }}>
            </div>

            {/* Second Row: Heading, Chapter Name and Verse Count */}
            <div className="card-header">
              <h2>{chapter.chapterName} <span className="tag">{chapter.verseCount}</span></h2>
            </div>

            {/* Third Row: Meaning */}
            <div className="card-meaning">
              <p>{chapter.meaning}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
